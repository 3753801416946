import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Print from '../images/print.svg'
import Alert from '../images/alert.svg'
import Student from '../images/student.svg'
import Invoice from '../images/invoice.svg'
import Stats from '../images/stats.svg'
import Planning from '../images/planning.svg'
import Flag from '../images/flag.svg'
import Flower from '../images/flower.svg'
import Polynesia from '../images/polynesia.svg'
import Responsive from '../images/responsive.svg'
import Site from '../images/site.svg'
import styles from './index.module.css'

const IndexPage = () => (
  <Layout>
    <div className={styles.index}>
    <SEO title="Otoschool" />
    <section className={`${styles.application} w3-container`}>
      <h1>Une <strong>solution</strong> complète pour votre auto-école !</h1>
      <p>Gagnez du temps dans la gestion de votre auto-école</p>
      <img src={Site} style={{width: `998px`, maxHeight: `492px`, display: `block`, margin: `auto`}} alt="Illustration d'otoschool"/>
      <a href="https://app.otoschool.fr/"><button type="button">Créer mon compte</button></a>
    </section>
    <section className={`${styles.functionnalities} w3-container`}>
      <h1>Gagnez du temps</h1>
      <div className="w3-row-padding">
        <div className="w3-third w3-container">
          <img src={Student} alt=""/>
          <p>GESTION DES <strong>FICHES CLIENTS</strong></p>
        </div>
        <div className="w3-third w3-container">
          <img src={Planning} alt=""/>
          <p>GESTION DU <strong>PLANNING</strong></p>
        </div>
        <div className="w3-third w3-container">
          <img src={Alert} alt=""/>
          <p><strong>OUTIL D'ALERTES</strong> PAR MAIL</p>
        </div>
      </div>
      <div className="w3-row-padding">
        <div className="w3-third w3-container">
          <img src={Invoice} alt=""/>
          <p>GESTION <strong>DES FACTURES & DES ENCAISSEMENTS</strong></p>
        </div>
        <div className="w3-third w3-container">
          <img src={Stats} alt=""/>
          <p><strong>STATISTIQUES</strong></p>
        </div>
        <div className="w3-third w3-container">
          <img src={Print} alt=""/>
          <p><strong>IMPRESSION</strong> DES DOCUMENTS POUR LA DTT</p>
        </div>
      </div>
    </section>
    <section className={`${styles.responsive} w3-container`}>
      <h1>Un logiciel accessible <strong>tous supports:</strong></h1>
      <p>PC, Mac, tablettes ou smartphone !</p>
      <img src={Responsive} style={{width: `630px`, maxHeight: `371px`, display: `block`, margin: `auto`}} alt="Site responsive design" />
    </section>
    <section className={`${styles.polynesia} w3-container`}>
      <p>Le logiciel de gestion conçu pour les <strong>auto-écoles polynésiennes</strong></p>
      <div className="w3-row-padding">
        <div className="w3-third w3-container">
          <img src={Flower} style={{width: `90px`, height: `90px`}} alt=""/>
        </div>
        <div className="w3-third w3-container">
          <img src={Flag} style={{width: `151px`, height: `100px`}} alt=""/>
        </div>
        <div className="w3-third w3-container">
          <img src={Polynesia} style={{width: `90px`, height: `90px`}} alt=""/>
        </div>
      </div>
    </section>
  </div>
  </Layout>
)

export default IndexPage
